<template>
  <UiPopup
    :model-value="modelValue"
    title="Delete phone number"
    primary-button-text="Delete"
    secondary-button-text="Cancel"
    size="small"
    :loading="loading"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="deletePhone"
  >
    <div>
      <span class="text-body-2"
        >Are you sure you want to delete <br />
        the number</span
      >
      <span class="text-subhead-4"> +{{ phone.code }}{{ phone.phone }} </span> ?
    </div>
  </UiPopup>
</template>

<script setup lang="ts">
import type { UserPhone } from '@/types'
import { useUiStore } from '~/store/ui'

const emits = defineEmits(['update:modelValue', 'input'])
const uiStore = useUiStore()

type Props = {
  modelValue: boolean
  phone: UserPhone
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const loading = ref(false)

const deletePhone = async () => {
  loading.value = true
  try {
    await useDeleteUserPhone(props.phone.id as number)
    emits('input')
    emits('update:modelValue', false)
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    loading.value = false
  }
}
</script>

<style scoped></style>
